<script>
export default {
    data: function() {
        return {
            data: {}
        };
    },
    mounted: function() {
      var vm = this;

      vm.load();
    },
    watch: {
        $route (to, from){
          var vm = this;

          vm.load();
        }
    },
    methods: {
        load: function() {
            var vm = this;

            if (vm.$route.params.id) {
                vm.create();
            } else {
                vm.createEmptyData();
            }
        },
        createEmptyData: function() {
            var vm = this;

            var data = {
                id: null,
                ref_no: null,
                name: null,
                email: null,
                company_name: null,
                notes: null
            };

            vm.data = Object.assign({}, data);
        },
        create: async function() {
            var vm = this;
            vm.createEmptyData();

            vm.$loading.on('loading');

            var resp = await vm.$api.Person.get(vm.$route.params.id);
            if (!resp.data.is_error) {
                var data = resp.data.data;

                vm.data = Object.assign({}, data);

            } else {
                vm.$flash.error(resp.data.message);
            }

            vm.$loading.off('loading');
        },
        submit: async function() {
            var vm = this;
            vm.$loading.on('loading');

            var data = vm._createPostData(vm.data);
            var resp = await vm.$api.Person.create(data);
            vm.$ac.refresh();

            if(!resp.data.is_error) {
                if (vm.$route.params.id) {
                    vm.create();
                } else {
                    vm.$router.push({ name: 'customer-detail', params: { id: resp.data.data }});
                }

                vm.$flash.success(resp.data.message);
            } else {
                vm.$flash.error(resp.data.message);
            }

            vm.$loading.off('loading');
        },
        _createPostData: function(data) {
            var vm = this;

            var _data = Object.assign({}, data);

            return _data;
        },
    }
}
</script>

<template>
    <section class="content">
        <div class="container-fluid">
            <wv-row>
                <wv-col>
                    <wv-tab-group :loading="$loading.get('loading')">
                        <wv-tab title="Detail">
                            <form action="#" role="form" v-on:submit.prevent="submit()">
                                <wv-row>
                                    <wv-text
                                        label="No. Ref - AUTO"
                                        placeholder="Masukan Ref No"
                                        :col="12"
                                        v-model="data.ref_no"></wv-text>
                                    <wv-text
                                        label="Nama"
                                        placeholder="Masukan Nama"
                                        :col="12"
                                        :required="true"
                                        v-model="data.name"></wv-text>
                                    <wv-text
                                        label="Perusahaan"
                                        placeholder="Masukan Nama Perusahaan"
                                        :col="12"
                                        :required="true"
                                        v-model="data.company_name"></wv-text>
                                    <wv-text
                                        label="No. Tlp"
                                        placeholder="Masukan nomor telepon"
                                        :col="12"
                                        v-model="data.phone"></wv-text>
                                    <wv-text
                                        type="email"
                                        label="Email"
                                        placeholder="Masukan Email"
                                        :col="12"
                                        v-model="data.email"></wv-text>
                                    <wv-textarea
                                        label="Alamat"
                                        placeholder="Masukan alamat"
                                        :col="12"
                                        v-model="data.address"></wv-textarea>
                                    <wv-textarea
                                        label="Catatan"
                                        placeholder="Masukan Catatan"
                                        :col="12"
                                        v-model="data.notes"></wv-textarea>
                                    <wv-col>
                                        <div class="float-right">
                                            <wv-cancel-button v-on:click="load()"></wv-cancel-button>
                                            <wv-submit-button></wv-submit-button>
                                        </div>
                                    </wv-col>
                                </wv-row>
                            </form>
                        </wv-tab>
                    </wv-tab-group>
                </wv-col>
            </wv-row>
        </div>
    </section>
</template>
