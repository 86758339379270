<script>
import MixinList from '../Mixins/MixinList.js';

export default {
  mixins: [MixinList],
  data: function() {
      return {
          routeName: 'invoice',
          detail: {
              date: this.$df.iso(new Date())
          },
          options: {
              status: undefined,
              person: undefined
          },
          selected: [],
          selectAll: false,
      }
  },
  mounted: function() {
      var vm = this;

      if (!vm.search.person_id) {
          vm.search.person_id = '';
      }

      vm.loadCustomer();
      vm.loadCategory();
      // vm.loadLogCategory();
  },
  watch: {
    selectAll: function(val) {
      var vm = this;

      if (val) {
        _.map(vm.list, function(x) {
          vm.selected.push(x.id);
          return x;
        });
      } else {
        vm.selected = [];
      }
    }
  },
  methods: {
        // ==========
        // Overide from mixin
        _search: async function() {
            var vm = this;

            var search = Object.assign({}, this.search);

            return await this.$api.Invoice.all(this.search);
        },
        _delete: async function(params) {
            return await this.$api.Invoice.delete(params)
        },
        _restore: async function(id) {
            return await this.$api.Invoice.restore(id);
        },
        loadCategory: function() {
            var vm = this;

            vm.$loading.on('loading-category');

            vm.$api.Category.all({page: 'all', group_by: 'invoice'}).then(function(resp) {
                var customer = resp.data.data;

                var list = _.map(customer, function(row) {
                    return { id: row.id, text: row.label };
                });

                vm.options.status = list;

                vm.$loading.off('loading-category');
            });
        },
        loadCustomer: async function() {
            var vm = this;

            vm.$loading.on('loading-customer');
            var resp = await vm.$api.Person.all({ page: 'all', order_by:{ column:'name',ordered:'asc'}});
            var data = resp.data.data;

            vm.options.person = _.map(data, function(row) {
                return {
                    id: row.id,
                    text:row.text
                }
            });

            // console.log('Person', vm.options.person);
            vm.$loading.off('loading-customer');
        },
        clear: function() {
          var vm = this;
          vm.search = {
            canceled: ""
          };
          vm.isSubmit = false;

          vm.doSearch();
        },
        exportXls: function() {
            var vm = this;

            vm.$loading.on('loading-export');
            vm.$api.Invoice.export(vm.search).then(function(resp) {
                if(!resp.data.is_error) {
                    var data = resp.data;

                    if(!data.is_error)
                        window.open(data.data.url);

                } else {
                    vm.$flash.error(resp.data.message);
                }

                vm.$loading.off('loading-export');
            });
        },
        importXls: function() {
            var el = this.$refs.fileInput;

            // Trigger element to open upload dialog
            el.click();
        },
        handleFileChange: function(e) {
            var vm = this;
            var file = e.target.files[0];
            var el = this.$refs.fileInput;

            // Reset to null again
            el.value = '';

            vm.$loading.on('loading-import');
            vm.$api.Invoice.import({file: file}).then(function(resp) {
                if(!resp.data.is_error) {
                    var data = resp.data;

                    if(!data.is_error)
                        vm._doSearch();

                    vm.$flash.success(resp.data.message);
                } else {
                    vm.$flash.error(resp.data.message);
                }

                vm.$loading.off('loading-import');
            });
        }
    }
}
</script>

<template>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-default">
              <div class="card-header">
                <h3 class="card-title">Pencarian</h3>
              </div>
              <form action="#" role="form" v-on:submit.prevent="doSearch()">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-sm-6">
                      <label>Kata Kunci</label>
                      <input type="text" class="form-control" id="keyword" placeholder="Masukkan Kata Kunci" v-model="search.keyword"/>
                    </div>
                    <wv-select2
                        v-bind:col="6"
                        label="Customer"
                        v-model="search.person_id"
                        placeholder="Pilih Customer"
                        v-bind:options="options.person"
                        v-bind:show-all="true"
                        v-bind:show-all-label="'Semua'"></wv-select2>
                    <wv-select
                        v-bind:col="6"
                        label="Status"
                        v-model="search.status_id"
                        placeholder="Pilih Status"
                        v-bind:options="options.status"
                        v-bind:show-all="true"
                        v-bind:show-all-label="'Semua'"></wv-select>
                    <div class="form-group col-sm-6">
                      <label>Status Lunas</label>
                        <select v-model="search.is_paid" class="form-control">
                          <option value="">Semua</option>
                          <option value="1">Lunas</option>
                          <option value="0">Belum Lunas</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-6">
                        <label>Tanggal (Dari)</label>
                        <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                        </div>
                        <datepicker v-model="search.date_from" />
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label>Tanggal (Ke)</label>
                        <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                        </div>
                        <datepicker v-model="search.date_to" />
                        </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <button type="button" class="btn btn-default" @click="clear">Batal</button>
                  <button type="submit" class="btn btn-primary">Cari</button>
                </div>
              </form>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card card-default">
              <box-overlay v-if="$loading.get('loading')" />
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12">
                      <router-link v-if="$ac.hasAccess('invoice_create')" class="btn btn-primary" :to="{name: 'invoice-create'}"><i class="fas fa-plus"></i> Tambah</router-link> <button class="btn btn-primary" v-if="$ac.hasAccess('invoice_read')" @click="exportXls" :disabled="$loading.get('loading-export')"><i class="fas fa-file-excel"></i> Export(xls) <spin v-if="$loading.get('loading-export')"></spin></button>
                    </div>
                    <div class="col-sm-12 mt-3">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width: 10px">No.</th>
                                        <th>No. Ref</th>
                                        <th>Status</th>
                                        <th>Perusahaan/PIC</th>
                                        <th class="clickable" @click="sortBy('created')">Tgl. Buat <sortable v-bind:order="$orderBy.getClass('created')" /></th>
                                        <th>Tgl. Bayar</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <template v-for="(tr, $index) in list">
                                    <tr>
                                        <td>{{ parseInt($index) + 1 }}</td>
                                        <td>
                                            {{ tr.ref_no }}
                                            <span class="badge bg-danger" v-if="!tr.is_paid">Belum Lunas</span>
                                        </td>
                                        <td>{{ tr.status }}</td>
                                        <td>{{ tr.company_name }} - {{ tr.company_person_name }}</td>
                                        <td>{{ tr.created }}</td>
                                        <td>{{ tr.paid }}</td>
                                        <td>{{ tr.total | currency }}</td>
                                        <td>
                                            <div v-if="search.deleted == 0 || search.deleted == null">
                                                <router-link v-if="$ac.hasAccess('invoice_read')" title="Ubah" class="btn btn-warning" :to="{name: 'invoice-detail', params: {id: tr.id}}"><i class="fas fa-pencil-alt"></i></router-link>
                                                <button-confirm v-if="$ac.hasAccess('invoice_delete')" title="Hapus"
                                                    @click="destroy(tr.id)"
                                                    v-bind:disabled="$loading.get('loading-delete-' + tr.id)"
                                                    v-bind:message="$confirm.delete">
                                                </button-confirm>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer clearfix">
                  <paging v-bind:model="paginator" v-on:goto="goto"/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
