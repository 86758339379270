<script>
import MixinList from '../Mixins/MixinList.js';

export default {
    mixins: [MixinList],
    data: function() {
        return {
            routeName: 'customer',
            options: {
                type: []
            }
        }
    },
    mounted: function() {
        var vm = this;
    },
    methods: {
        // ==========
        // Overide from mixin
        _search: async function() {
            return await this.$api.Person.all(this.search);
        },
        _delete: async function(params) {
            return await this.$api.Person.delete(params)
        },
        _restore: async function(id) {
            return await this.$api.Person.restore(id);
        },
        // ==========
        clear: function() {
            var vm = this;
            vm.search = {};

            vm.doSearch();
        },
        exportXls: function() {
            var vm = this;

            vm.$loading.on('loading-export');
            vm.$api.Person.export(vm.search).then(function(resp) {
                if(!resp.data.is_error) {
                    var data = resp.data;

                    if(!data.is_error)
                        window.open(data.data.url);

                } else {
                    vm.$flash.error(resp.data.message);
                }

                vm.$loading.off('loading-export');
            });
        },
        importXls: function() {
            var el = this.$refs.fileInput;

            // Trigger element to open upload dialog
            el.click();
        },
        handleFileChange: function(e) {
            var vm = this;
            var file = e.target.files[0];
            var el = this.$refs.fileInput;
            var category = this.$route.query.category;

            // Reset to null again
            el.value = '';

            vm.$loading.on('loading-import');
            vm.$api.Person.import({file: file, category}).then(function(resp) {
                if(!resp.data.is_error) {
                    var data = resp.data;

                    if(!data.is_error)
                        vm._doSearch();

                    vm.$flash.success(resp.data.message);
                } else {
                    vm.$flash.error(resp.data.message);
                }

                vm.$loading.off('loading-import');
            });
        }
    }
}
</script>

<template>
    <section class="content">
        <div class="container-fluid">
            <wv-row>
                <wv-col>
                    <wv-card title="Pencarian">
                        <form action="#" role="form" v-on:submit.prevent="doSearch()">
                            <wv-row>
                                <wv-text
                                    label="Kata Kunci"
                                    placeholder="Masukan Kata Kunci"
                                    v-bind:col="12"
                                    v-model="search.keyword"></wv-text>
                                <wv-col class="text-right">
                                    <wv-cancel-button v-on:click="clear()"></wv-cancel-button>
                                    <wv-submit-button>Cari</wv-submit-button>
                                </wv-col>
                            </wv-row>
                        </form>
                    </wv-card>
                </wv-col>

                <wv-col>
                    <wv-card v-bind:loading="$loading.get('loading')">
                        <wv-row>
                            <wv-col>
                                <router-link
                                    class="btn btn-primary"
                                    :to="{name: 'customer-create'}"
                                    v-if="$ac.hasAccess('customer_create') ">
                                    <i class="fas fa-plus"></i> Tambah</router-link> <button class="btn btn-primary" v-if="$ac.hasAccess('customer_create')" @click="exportXls" :disabled="$loading.get('loading-export')"><i class="fas fa-file-excel"></i> Export(xls) <spin v-if="$loading.get('loading-export')"></spin></button> <button class="btn btn-primary" v-if="$ac.hasAccess('customer_create')" @click="importXls" :disabled="$loading.get('loading-import')"><i class="fas fa-file-excel"></i> Import(xls)<spin v-if="$loading.get('loading-import')"></spin></button>
                                <input type="file" style="display: none;" @change="handleFileChange" ref="fileInput">
                            </wv-col>
                            <wv-col class="mt-3">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style="width: 10px">#</th>
                                                <th>No Ref</th>
                                                <th>Nama</th>
                                                <th>Company Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(tr, $index) in list" :key="$index">
                                                <td>{{ parseInt($index) + 1 }}</td>
                                                <td>{{ tr.ref_no }}</td>
                                                <td>{{ tr.name }}</td>
                                                <td>{{ tr.company_name }}</td>
                                                <td>
                                                    <router-link
                                                        v-if="$ac.hasAccess('customer_read')"
                                                        title="Ubah"
                                                        class="btn btn-warning"
                                                        :to="{name: 'customer-detail', params: {id: tr.id}}">
                                                        <i class="fas fa-pencil-alt"></i></router-link>
                                                    <button-confirm
                                                        v-if="$ac.hasAccess('customer_delete')"
                                                        title="Hapus"
                                                        @click="destroy(tr.id)"
                                                        v-bind:disabled="$loading.get('loading-delete-' + tr.id)"
                                                        v-bind:message="$confirm.delete">
                                                        </button-confirm>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </wv-col>
                            <wv-col>
                                <paging v-bind:model="paginator" v-on:goto="goto"/>
                            </wv-col>
                        </wv-row>
                    </wv-card>
                </wv-col>
            </wv-row>
        </div>
    </section>
</template>
