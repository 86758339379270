<script>
export default {
    data: function() {
        return {
            data: {},
            logo: {
                url: null,
                file: []
            },
            signature: {
                url: null,
                file: []
            }
        };
    },
    created: function() {
        this.$appConfig.stopRefresh();
    },
    mounted: function() {
        var vm = this;

        vm.createEmptyData();
        vm.create();
    },
    methods: {
        createEmptyData: function() {
            var vm = this;

            var data = {
                company_name: null,
                company_address: null,
                company_email: null,
                company_phone: null,

                logo: [],
                signature: [],

                _delete_logo: [],
                _delete_signature: [],
            };

            vm.data = Object.assign({}, data);
        },
        create: async function() {
            var vm = this;
            vm.$loading.on('loading');
            var resp = await vm.$api.Config.all({});

            if (!resp.data.is_error) {
                var data = resp.data.data;

                //delete soon
                data.files = {};
                data._delete_file = [];
                // --delete soon

                vm.logo.url = data.logo.url;

                var config = Object.assign({}, data);
                vm.$appConfig.setData(config);

                if(!Array.isArray(data))
                    vm.data = Object.assign({}, data);

                console.log('from create method', vm.data);
            } else {
                vm.$flash.error(resp.data.message);
            }

            vm.$loading.off('loading');

        },
        submit: async function() {
            var vm = this;

            vm.$loading.on('loading');

            var data = vm._createPostData(vm.data);

            console.log('after create post data', data);

            var resp = await vm.$api.Config.create(data);

            if(!resp.data.is_error) {
                vm.create();

                vm.$flash.success(resp.data.message);
            } else {
                vm.$flash.error(resp.data.message);
            }

            vm.$loading.off('loading');
        },
        _createPostData: function(data) {
            var vm = this;

            // debugger;

            data.files = {
                signature: vm.signature.file
            };

            var _data = Object.assign({}, data);

            return _data;
        },
        addLogo: function(event) {
            var vm = this;
            var logo = vm.logo;

            if (logo.file.length > 0)
                if (typeof logo.file[0].id !== 'undefined' && logo.file[0].id !== null && !logo._delete_file.includes(logo.file[0].id))
                    vm.logo._delete_file.push(logo.file[0].id);

            var file = event.target.files[0];
            file.value = file.name;
            file._hash = Math.getRandomHash();

            logo.file[0] = file;

            var elLogo = this.$refs.fileLogoInput;
            elLogo.value = '';

            vm.logo = Object.assign({}, logo);
        },
        addSignature: function(event) {
            var vm = this;
            var data = vm.data;
            var file = data.files;

            // Check apakah file ada atau tidak.
            // Jika ada, hapus terlebih dahulu
            if (file.length > 0) {
                if (typeof file[0].id !== 'undefined' && file[0].id !== null && !data._delete_file.includes(file[0].id)) {
                    vm.data._delete_file.push(file[0].id);
                }
            }

            // Tambahkan file baru
            file = event.target.files[0];
            file.value = file.name;
            file._hash = Math.getRandomHash();

            // Tambahkan ke object data
            vm.signature.file = file;

            vm.data = Object.assign({}, data);
        },
        addFile: function(event) {
            var vm = this;
            var data = vm.data;

            if (data.file.length > 0) {
                if (typeof data.file[0].id !== 'undefined' && data.file[0].id !== null && !data._delete_file.includes(data.file[0].id)) {
                    vm.data._delete_file.push(data.file[0].id);
                }
            }

            var file = event.target.files[0];
            file.value = file.name;
            file._hash = Math.getRandomHash();

            data.file[0] = file;

            vm.data = Object.assign({}, data);
        },
        deleteFile: function(file) {
            var vm = this;

            console.log('data yang ingin di delete', file);

            vm.data._delete_file.push(vm.data.file[0].id);
            vm.data.file.splice(0, 1);
            vm.data.logo = null;
        }
    }
}
</script>
<template>
    <section class="content">
        <div class="container-fluid">
            <wv-row>
                <wv-col>
                    <div class="card card-default">
                        <box-overlay v-if="$loading.get('loading')" />
                        <div class="card-header">
                            <h3 class="card-title">Biodata Perusahaan</h3>
                        </div> <!-- card-header -->
                        <form action="#" role="form" v-on:submit.prevent="submit()">
                            <div class="card-body">
                                <wv-row>
                                    <wv-text
                                        label="Nama"
                                        placeholder="Masukan Nama Perusahaan"
                                        v-bind:col="12"
                                        v-model="data.company_name">
                                    </wv-text>
                                    <wv-text
                                        label="Email"
                                        placeholder="Masukan Email Perusahaan"
                                        v-bind:col="12"
                                        v-model="data.company_email">
                                    </wv-text>
                                    <wv-text
                                        label="No. Telp"
                                        placeholder="Masukan No. Telepon Perusahaan"
                                        v-bind:col="12"
                                        v-model="data.company_phone">
                                    </wv-text>
                                    <!--
                                    <wv-textarea
                                        label="Alamat"
                                        placeholder="Masukan Alamat Perusahaan"
                                        v-bind:col="12"
                                        v-model="data.company_address">
                                    </wv-textarea>
                                    -->
                                    <div class="form-group col-sm-12">
                                        <label>Tanda Tangan</label><br>
                                        <img :src="data.signature.url" class="my-3" height="100px" width="auto" v-if="data.signature.url != null"><br>
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="signatureImg" name="upload" v-on:change="addSignature($event)" ref="fileSignatureInput">
                                            <label
                                                class="custom-file-label"
                                                for="signatureImg">
                                                <span v-if="signature.file && signature.file.length > 0 && typeof signature.file[0].value !== 'undefined'">
                                                    {{ signature.file[0].value }}
                                                </span></label>
                                        </div>
                                        <small>Resolusi: 512x512px</small>
                                    </div>
                                </wv-row>
                            </div> <!-- card-body -->
                            <div class="card-body">
                                <wv-row>
                                    <wv-col class="text-right" v-if="$ac.hasAccess('config_create')">
                                        <wv-cancel-button v-on:click="create()"></wv-cancel-button>
                                        <wv-submit-button></wv-submit-button>
                                    </wv-col>
                                </wv-row>
                            </div>
                        </form>
                    </div> <!-- card card-default -->
                </wv-col>
            </wv-row>
        </div>
    </section>
</template>