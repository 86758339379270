export default function Person(vm) {
    this.all = function(params) {
        var url = '/api/person/list?' + $.param(params);

        return vm.get(url);
    };

    this.create = function(params) {
        var url = '/api/person/create';

        return vm.post(url, params);
    };

    this.get = function(id) {
        var url = '/api/person/detail/' + id;

        return vm.get(url);
    };

    this.delete = function(params) {
        var url = null;

        url = '/api/person/' + params.id;

        return vm.delete(url);
    };

    this.restore = function(id){
        var url = '/api/person/restore/' + id;

        return vm.get(url);
    };


    this.export = function(params) {
        var url = '/api/person/export?' + $.param(params);

        return vm.get(url);
    };

    this.import = function(params) {
        var url = '/api/person/import';

        return vm.upload(url, params);
    };
};