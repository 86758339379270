<script>
export default {
    data: function() {
        return {
          data: {},
          total_bonus: null,
          statistic: null,
          charts: {
            gasBalance: {},
            profitSharingGasFee: {},
            profitSharingAffiliate: {},
            profitSharingReward: {}
          },
          options: {
            basicWithCustomNumberToCurrencyBusd: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    callback: function(value, index, values) {
                      var value = '$' + value;
                      return value;
                    },
                    fontSize: 9
                  }
                }]
              },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, chart) {
                    var value = parseFloat(tooltipItem.value).toFixed(2);
                    var number = chart.datasets[tooltipItem.datasetIndex].label + ': $' + value.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

                    return number;
                  }
                }
              }
            },
            basic: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    fontSize: 9
                  }
                }]
              }
            },
            basicWithCustomNumberProfitSharingGasFee: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    callback: function(value, index, values) {
                      var number = '$' + value;
                      return number;
                    },
                    fontSize: 9
                  }
                }]
              },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, chart) {
                    var value = parseFloat(tooltipItem.value).toFixed(2);
                    var number = chart.datasets[tooltipItem.datasetIndex].label + ': $' + value.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

                    return number;
                  }
                }
              }
            },
            basicWithCustomNumberProfitSharingAffiliate: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    callback: function(value, index, values) {
                      var number = 'Rp.' + value;
                      return number;
                    },
                    fontSize: 9
                  }
                }]
              },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, chart) {
                    var value = parseFloat(tooltipItem.value).toFixed(2);
                    var number = chart.datasets[tooltipItem.datasetIndex].label + ': Rp.' + value.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

                    return number;
                  }
                }
              }
            },
            basicWithCustomNumberProfitSharingReward: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    callback: function(value, index, values) {
                      var number = value + ' Poin';
                      return number;
                    },
                    fontSize: 9
                  }
                }]
              },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, chart) {
                    var value = parseFloat(tooltipItem.value).toFixed(2);
                    var number = chart.datasets[tooltipItem.datasetIndex].label + ': ' + value.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' Poin';

                    return number;
                  }
                }
              }
            },
          }
        }
    },
    mounted: function() {
        var vm = this;

        vm.loadBonusMemberDashboard();
        vm.loadStatisticMemberDashboard();
        vm.getGasBalanceChart();
        vm.getChartProfitSharingGasFee();
        vm.getChartProfitSharingAffiliate();
        vm.getChartProfitSharingReward();
    },
    methods: {
      loadBonusMemberDashboard: async function() {
          var vm = this;

          vm.$loading.on('loading-total-bonus');

            var resp = await vm.$api.Report.bonusMemberDashboard.all();
            var data = resp.data.data;

            vm.total_bonus = Object.assign({}, data);

          vm.$loading.off('loading-total-bonus');
      },
      loadStatisticMemberDashboard: async function() {
          var vm = this;

          vm.$loading.on('loading-statistic');

            var resp = await vm.$api.Report.statisticMemberDashboard.all();
            var data = resp.data.data;

            vm.statistic = Object.assign({}, data);

          vm.$loading.off('loading-statistic');
      },
      getGasBalanceChart: async function() {
            var vm = this;

            vm.$loading.on('loading-gas-balance-chart');
            var resp = await vm.$api.Report.Dashboard.reportGasBalance();
            var data = resp.data.data;

            vm.charts.gasBalance = data;
            vm.$loading.off('loading-gas-balance-chart');
      },
      getChartProfitSharingGasFee: async function() {
          var vm = this;

          vm.$loading.on('loading-chart-profit-sharing-gas-fee');
          var resp = await vm.$api.Report.Dashboard.reportChartProfitSharingGasFee();
          var data = resp.data.data;

          vm.charts.profitSharingGasFee = data;
          vm.$loading.off('loading-chart-profit-sharing-gas-fee');
      },
      getChartProfitSharingAffiliate: async function() {
          var vm = this;

          vm.$loading.on('loading-chart-profit-sharing-affiliate');
          var resp = await vm.$api.Report.Dashboard.reportChartProfitSharingAffiliate();
          var data = resp.data.data;

          vm.charts.profitSharingAffiliate = data;
          vm.$loading.off('loading-chart-profit-sharing-affiliate');
      },
      getChartProfitSharingReward: async function() {
          var vm = this;

          vm.$loading.on('loading-chart-profit-reward');
          var resp = await vm.$api.Report.Dashboard.reportChartProfitSharingReward();
          var data = resp.data.data;

          vm.charts.profitSharingReward = data;
          vm.$loading.off('loading-chart-profit-reward');
      },
      copyCodeAffiliate: function() {
          var vm = this;
          var message;

          if(vm.$ac.getUser().code_affiliate){
              navigator.clipboard.writeText(vm.$ac.getUser().url_code_affiliate);

              message = "Kode Affiliate Berhasil di copy.";
              vm.$flash.success(message);
          }
      },
      upgradeMembership: async function () {
            var vm = this;
            vm.$loading.on('loading-upgrade');

            var resp = await vm.$api.Cart.addMembership(vm.$ac.getUser().id);

            if(!resp.data.is_error) {
                vm.$router.push({ name: 'cart', query: { person_id: vm.$ac.getUser().id }});
                vm.$flash.success(resp.data.message);
            } else {
                vm.$flash.error(resp.data.message);
            }

            vm.$loading.off('loading-upgrade');
        }
    }
}
</script>

<template>
  <section class="content">
      <div class="container-fluid">
        <div class="row" v-if="$ac.getUser().person_id">
          <div class="col-lg-4">
            <div class="small-box bg-info">
              <box-overlay v-if="$ac.getUser().binance_balance == null" />
              <div class="inner">
                <h3>${{ $pf.formatPrice($ac.getUser().binance_balance) }}</h3>
                <p>Binance BUSD</p>
              </div>
              <div class="icon">
                <i class="ion ion-cash"></i>
              </div>
              <a href="#" class="small-box-footer">&nbsp;</a>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="small-box bg-success">
              <box-overlay v-if="$ac.getUser().balance_busd == null" />
              <div class="inner">
                <h3>${{ $pf.formatPrice($ac.getUser().balance_busd) }}</h3>
                <p>Gas Balance</p>
              </div>
              <div class="icon">
                <i class="ion ion-cash"></i>
              </div>
              <router-link
                class="small-box-footer"
                :to="{name: 'deposit'}">
                Deposit <i class="fas fa-arrow-circle-right"></i>
              </router-link>
            </div>
          </div>
          <div class="col-lg-12">
            <h3>Profit Sharing</h3>
          </div>
          <br><br><br>
          <div class="col-lg-4">
            <div class="small-box bg-warning">
              <box-overlay v-if="$ac.getUser().profit_sharing_gas_fee == null" />
              <div class="inner">
                <h3>${{ $pf.formatPrice($ac.getUser().profit_sharing_gas_fee) }}</h3>
                <p>Gas Fee</p>
              </div>
              <div class="icon">
                <i class="ion ion-cash"></i>
              </div>
              <router-link
                class="small-box-footer"
                :to="{name: 'withdraw'}">
                Withdraw <i class="fas fa-arrow-circle-right"></i>
              </router-link>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="small-box bg-danger">
              <box-overlay v-if="$ac.getUser().profit_sharing_affiliate_idr == null" />
              <div class="inner">
                <h3>{{ $ac.getUser().profit_sharing_affiliate_idr | currency }}</h3>
                <p>Affiliate</p>
              </div>
              <div class="icon">
                <i class="ion ion-card"></i>
              </div>
              <router-link
                class="small-box-footer"
                :to="{name: 'withdraw'}">
                Withdraw <i class="fas fa-arrow-circle-right"></i>
              </router-link>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="small-box bg-secondary">
              <box-overlay v-if="$ac.getUser().profit_sharing_affiliate_reward_point == null" />
              <div class="inner">
                <h3>{{ $pf.formatPrice($ac.getUser().profit_sharing_affiliate_reward_point) }} Poin</h3>
                <p>Reward</p>
              </div>
              <div class="icon">
                <i class="ion ion-pinpoint"></i>
              </div>
              <a href="#" class="small-box-footer">&nbsp;</a>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card bg-dark">
              <div class="card-footer">
                <div class="row">

                  <div class="col-sm-4 col-6">
                    <div class="description-block border-right">
                      <span class="description-percentage">Type</span>
                      <h5 class="description-header">{{ $ac.getUser().type_label }}</h5>
                      <span class="description-text text-capitalize" v-if="$ac.getUser().type == 'free' || ($ac.getUser().expired < 7)">
                          <button type="button" class="btn btn-xs btn-success mt-1" :disabled="$loading.get('loading-upgrade')" @click="upgradeMembership()">
                              <spin v-if="$loading.get('loading-upgrade')"></spin> Upgrade
                          </button>
                      </span>
                    </div>
                  </div>

                  <div class="col-sm-4 col-6">
                    <div class="description-block border-right">
                      <span class="description-percentage">Tgl. Expired</span>
                      <h5 class="description-header text-capitalize">{{ $ac.getUser().date_expired_partner }}</h5>
                      <span class="description-text text-capitalize" v-if="$ac.getUser().expired < 7">Aktif Sampai {{ $ac.getUser().expired }} Hari</span>
                    </div>
                  </div>

                  <div class="col-sm-4 col-12">
                    <div class="description-block">
                      <span class="description-percentage">Kode Affiliate</span>
                      <h5 class="description-header text-capitalize">{{ $ac.getUser().code_affiliate }}</h5>
                      <button type="button" class="btn btn-xs btn-primary mt-1" @click="copyCodeAffiliate()"><i class="fa fa-copy"></i> Copy</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <wv-col class="col-lg-12">
            <div class="card">
              <box-overlay v-if="$loading.get('loading-gas-balance-chart')" />
              <div class="card-body">
                <h3>Gas Balance</h3>
                <div class="tab-content p-0">
                  <line-chart :chart-data="charts.gasBalance" :options="options.basicWithCustomNumberToCurrencyBusd" />
                </div>
              </div>
            </div>
          </wv-col>
          <wv-col class="col-lg-4">
            <div class="card">
              <box-overlay v-if="$loading.get('loading-chart-profit-sharing-gas-fee')" />
              <div class="card-body">
                <h3>Gas Fee</h3>
                <div class="tab-content p-0">
                  <line-chart :chart-data="charts.profitSharingGasFee" :options="options.basicWithCustomNumberProfitSharingGasFee" />
                </div>
              </div>
            </div>
          </wv-col>
          <wv-col class="col-lg-4">
            <div class="card">
              <box-overlay v-if="$loading.get('loading-chart-profit-sharing-affiliate')" />
              <div class="card-body">
                <h3>Affiliate</h3>
                <div class="tab-content p-0">
                  <line-chart :chart-data="charts.profitSharingAffiliate" :options="options.basicWithCustomNumberProfitSharingAffiliate" />
                </div>
              </div>
            </div>
          </wv-col>
          <wv-col class="col-lg-4">
            <div class="card">
              <box-overlay v-if="$loading.get('loading-chart-profit-reward')" />
              <div class="card-body">
                <h3>Reward</h3>
                <div class="tab-content p-0">
                  <line-chart :chart-data="charts.profitSharingReward" :options="options.basicWithCustomNumberProfitSharingReward" />
                </div>
              </div>
            </div>
          </wv-col>
          <wv-col class="col-lg-6">
            <wv-card v-bind:loading="$loading.get('loading-total-bonus')" title="Total Bonus(Member)">
                <wv-row>
                  <wv-col>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Keterangan</th>
                            <th class="text-right">Nominal</th>
                            <th>Unit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(tr, $index) in total_bonus" :key="tr.id">
                              <td><a :href="tr.link">{{ tr.title }}</a></td>
                              <td class="text-right">{{ $pf.formatPrice(tr.amount) }}</td>
                              <td>{{ tr.currency }}</td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </wv-col>
                </wv-row>
            </wv-card>
          </wv-col>
          <wv-col class="col-lg-6">
            <wv-card v-bind:loading="$loading.get('loading-statistic')" title="Statistic(Member)">
              <wv-row>
                <wv-col>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Keterangan</th>
                          <th class="text-right">Nominal</th>
                          <th>Unit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(tr, $index) in statistic" :key="tr.id">
                            <td><a :href="tr.link">{{ tr.title }}</a></td>
                            <td class="text-right">{{ $pf.formatPrice(tr.amount) }}</td>
                            <td>{{ tr.currency }}</td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </wv-col>
              </wv-row>
            </wv-card>
          </wv-col>
        </div>
      </div>
  </section>
</template>