// Pages
import InvoiceDetail from '../Pages/InvoiceDetail.vue';
import InvoiceIndex from '../Pages/InvoiceIndex.vue';

export default [
    {
        name: 'invoice',
        path: 'invoice',
        component: InvoiceIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Invoice'
        }
    },
    {
        name: 'invoice-create',
        path: 'invoice/create',
        component: InvoiceDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Invoice', url: '/invoice'},
                { title: null, active: true }
            ],
            pageTitle: 'Buat Invoice'
        }
    },
    {
        name: 'invoice-detail',
        path: 'invoice/detail/:id',
        component: InvoiceDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Invoice', url: '/invoice' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Invoice'
        }
    }
]