export default function Invoice(vm) {
    this.all = function(params) {
        var url = '/api/invoice/list?' + $.param(params);

        return vm.get(url);
    };

    this.create = function(params) {
        var url = '/api/invoice/create';

        return vm.post(url, params);
    };

    this.get = function(id) {
        var url = '/api/invoice/detail/' + id;

        return vm.get(url);
    };

    this.delete = function(params) {
        var url = '/api/invoice/' + params.id;

        return vm.delete(url);
    };

    this.download = function(id) {
        var params = {};

        var url = '/api/invoice/download/' + id;

        return vm.get(url);
    };

    this.export = function(params) {
        var url = '/api/invoice/export?' + $.param(params);

        return vm.get(url);
    };
};