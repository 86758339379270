// Pages
import AdminDetail from '../Pages/AdminDetail.vue';
import AdminIndex from '../Pages/AdminIndex.vue';
import CategoryIndex from '../Pages/CategoryIndex.vue';
import CategoryList from '../Pages/CategoryList.vue';
//import MemberDetail from '../Pages/MemberDetail.vue';
//import MemberIndex from '../Pages/MemberIndex.vue';
import HakAksesDetail from '../Pages/HakAksesDetail.vue';
import HakAksesIndex from '../Pages/HakAksesIndex.vue';
//import GiftCardCashIndex from '../Pages/GiftCardCashIndex.vue';
//import GiftCardCashDetail from '../Pages/GiftCardCashDetail.vue';
//import OfferwallIndex from '../Pages/OfferwallIndex.vue';
//import OfferwallDetail from '../Pages/OfferwallDetail.vue';
//import BroadcastIndex from '../Pages/BroadcastIndex.vue';
//import BroadcastDetail from '../Pages/BroadcastDetail.vue';
//import BroadcastEdit from '../Pages/BroadcastEdit.vue';
//import ItemIndex from '../Pages/ItemIndex.vue';
//import ItemDetail from '../Pages/ItemDetail.vue';

import CustomerIndex from '../Pages/CustomerIndex.vue';
import CustomerDetail from '../Pages/CustomerDetail.vue';
//import ItemCustomerIndex from '../Pages/ItemCustomerIndex.vue';
//import ItemCustomerDetail from '../Pages/ItemCustomerDetail.vue';
//import QuotationIndex from '../Pages/QuotationIndex.vue';
//import QuotationDetail from '../Pages/QuotationDetail.vue';
//import QuotationOnlineIndex from '../Pages/QuotationOnlineIndex.vue';
//import QuotationOnlineDetail from '../Pages/QuotationOnlineDetail.vue';

// Temporary
/* =================
var temp = [
    {
        name: 'admin',
        path: 'account',
        component: AdminIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Admin'
        }
    },
    {
        name: 'admin-create',
        path: 'account/create',
        component: AdminDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Admin', url: '/account' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Admin'
        }
    },
    {
        name: 'admin-detail',
        path: 'account/detail/:id',
        component: AdminDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Admin', url: '/account' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Admin'
        }
    },
    {
        name: 'hak-akses',
        path: 'hak-akses',
        component: HakAksesIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Hak Akses'
        }
    },
    {
        name: 'hak-akses-detail',
        path: 'hak-akses/detail/:id',
        component: HakAksesDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Hak Akses', url: '/hak-akses' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Hak Akses'
        }
    },
    {
        name: 'hak-akses-create',
        path: 'hak-akses/create',
        component: HakAksesDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Hak Akses', url: '/hak-akses' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Hak Akses'
        }
    },
    {
        name: 'member',
        path: 'member',
        component: MemberIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Member'
        }
    },
    {
        name: 'member-create',
        path: 'member/create',
        component: MemberDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Member', url: '/member' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Member'
        }
    },
    {
        name: 'member-detail',
        path: 'member/detail/:id',
        component: MemberDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Member', url: '/member' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Member'
        }
    },
    {
        name: 'member-verification',
        path: 'member/verification/:hash',
        component: MemberIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Member Verification'
        }
    },
    {
        name: 'broadcast',
        path: 'broadcast',
        component: BroadcastIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Broadcast'
        }
    },
    {
        name: 'broadcast-create',
        path: 'broadcast/create',
        component: BroadcastDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Broadcast', url: '/broadcast' },
                { title: null, active: true }
            ],
            pageTitle: 'Broadcast Create'
        }
    },
    {
        name: 'broadcast-detail',
        path: 'broadcast/detail/:id',
        component: BroadcastEdit,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Broadcast', url: '/broadcast' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Broadcast'
        }
    },
    {
        name: 'offerwall',
        path: 'offerwall',
        component: OfferwallIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Offerwalls'
        }
    },
    {
        name: 'offerwall-detail',
        path: 'offerwall/detail/:id',
        component: OfferwallDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Offerwall', url: '/offerwall' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Offerwall'
        }
    },
    {
        name: 'offerwall-create',
        path: 'offerwall/create',
        component: OfferwallDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Offerwall', url: '/offerwall' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Offerwall'
        }
    },
    {
        name: 'list-category',
        path: 'category',
        component: CategoryList,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'ListKategori'
        }
    },
    {
        name: 'unit',
        path: 'category/unit',
        component: CategoryIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'List Kategori', url: '/category' },
                { title: null, active: true }
            ],
            pageTitle: 'Barang - Satuan'
        }
    },
    {
        name: 'branch',
        path: 'category/branch',
        component: CategoryIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'List Kategori', url: '/category' },
                { title: null, active: true }
            ],
            pageTitle: 'Cabang'
        }
    },
    {
        name: 'brand',
        path: 'category/brand',
        component: CategoryIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'List Kategori', url: '/category' },
                { title: null, active: true }
            ],
            pageTitle: 'Merk'
        }
    },
    {
        name: 'gift-card-cash',
        path: 'gift-card-cash',
        component: GiftCardCashIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Gift Card & Cash'
        }
    },
    {
        name: 'gift-card-cash-create',
        path: 'gift-card-cash/create',
        component: GiftCardCashDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Gift Card & Cash', url: '/gift-card-cash' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Gift Card & Cash'
        }
    },
    {
        name: 'gift-card-cash-detail',
        path: 'gift-card-cash/detail/:id',
        component: GiftCardCashDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Gift Card & Cash', url: '/gift-card-cash' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Gift Card & Cash'
        }
    },
    {
        name: 'customer',
        path: 'customer',
        component: CustomerIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Customer'
        }
    },
    {
        name: 'customer-create',
        path: 'customer/create',
        component: CustomerDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Customer', url: '/customer' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Customer'
        }
    },
    {
        name: 'customer-detail',
        path: 'customer/detail/:id',
        component: CustomerDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Customer', url: '/customer' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Customer'
        }
    },
    {
        name: 'item',
        path: 'item',
        component: ItemIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Barang'
        }
    },
    {
        name: 'item-create',
        path: 'item/create',
        component: ItemDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Barang', url: '/item' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Barang'
        }
    },
    {
        name: 'item-detail',
        path: 'item/detail/:id',
        component: ItemDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Barang', url: '/item' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Barang'
        }
    },
    {
        name: 'item-customer',
        path: 'item-customer',
        component: ItemCustomerIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Barang Customer'
        }
    },
    {
        name: 'item-customer-create',
        path: 'item-customer/create',
        component: ItemCustomerDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Barang Customer', url: '/item-customer' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Barang Customer'
        }
    },
    {
        name: 'item-customer-detail',
        path: 'item-customer/detail/:id',
        component: ItemCustomerDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Barang Customer', url: '/item-customer' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Barang Customer'
        }
    },
    {
        name: 'quotation',
        path: 'quotation',
        component: QuotationIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Quotation'
        }
    },
    {
        name: 'quotation-create',
        path: 'quotation/create',
        component: QuotationDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Quotation', url: '/quotation' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Quotation'
        }
    },
    {
        name: 'quotation-detail',
        path: 'quotation/detail/:id',
        component: QuotationDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Quotation', url: '/quotation' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Quotation'
        }
    },
    {
        name: 'quotation-online',
        path: 'quotation-online',
        component: QuotationOnlineIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Quotation Online'
        }
    },
    {
        name: 'quotation-online-create',
        path: 'quotation-online/create',
        component: QuotationOnlineDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Quotation Online', url: '/quotation-online' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Quotation Online'
        }
    },
    {
        name: 'quotation-online-detail',
        path: 'quotation-online/detail/:id',
        component: QuotationOnlineDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Quotation Online', url: '/quotation-online' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Quotation Online'
        }
    }
];
*/

var list = [
    {
        name: 'hak-akses',
        path: 'hak-akses',
        component: HakAksesIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Hak Akses'
        }
    },
    {
        name: 'hak-akses-detail',
        path: 'hak-akses/detail/:id',
        component: HakAksesDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Hak Akses', url: '/hak-akses' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Hak Akses'
        }
    },
    {
        name: 'hak-akses-create',
        path: 'hak-akses/create',
        component: HakAksesDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Hak Akses', url: '/hak-akses' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Hak Akses'
        }
    },
    {
        name: 'customer',
        path: 'customer',
        component: CustomerIndex,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: null, active: true }
            ],
            pageTitle: 'Customer'
        }
    },
    {
        name: 'customer-create',
        path: 'customer/create',
        component: CustomerDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Customer', url: '/customer' },
                { title: null, active: true }
            ],
            pageTitle: 'Create Customer'
        }
    },
    {
        name: 'customer-detail',
        path: 'customer/detail/:id',
        component: CustomerDetail,
        meta: {
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Customer', url: '/customer' },
                { title: null, active: true }
            ],
            pageTitle: 'Detail Customer'
        }
    }
];

export default list;