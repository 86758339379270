<script>
import Sidebar from '../../Components/Sidebar.vue';

export default {
    data: function() {
        return {
            options: {
              items: undefined,
            },
            discount:0,
            /*
             * {
             *  id: Item ID
             *  name: item name
             *  unit_id: unit id
             *  unit: unit label
             *  qty: qty
             * }
             */
            data: {},
        };
    },
    props: {
        'title': String,
        'value': Object,
        'success': Function,
        'apartment': String,
        'edit': {
            required: false,
            type: Boolean
        }
    },
    components: {
        Sidebar
    },
    mounted: function() {
      var vm = this;

      vm.load();
    },
    methods: {
        format: function() {
            var vm = this;

            /*
            var item = _.find(vm.options.items, function(num) {
                        return num.id == vm.value.item_id;
                    });

            vm.value.item = item.model.name;
             */
        },
        submit: function() {
            this.format();

            this.success(this.value);
            this.$sidebar.hide()
        },
        load: function() {
            // this.loadItems();
        },
        loadItems: async function() {
            var vm = this;

            vm.$loading.on('loading-item');
            var resp = await vm.$api.Item.all({ page: 'all', category: 'product', apartment: vm.apartment, include_id: vm.value.item_id });
            var data = resp.data.data;

            var item = _.map(data, function(row) {
                var text = [
                    row.ref_no,
                    row.apartment,
                    row.name
                ].join(' - ');

                return { id: row.id, text: text, model: row };
            });

            vm.options.items = item;
            if (vm.value.item_id != null) {
               var row = _.findWhere(item, { id: parseInt(vm.value.item_id) });
               vm.discount = row.model.discount;
            }
            vm.$loading.off('loading-item');
        },
        onChangeItem: function() {
            var vm = this;
            var list = vm.options.items;

            // Find selected item
            var row = _.findWhere(list, { id: parseInt(vm.value.item_id) });

            vm.value.item_ref_no = row.model.ref_no;
            // Update VAT based on selected item
            vm.value.vat = row.model.vat;
            // Update price
            vm.value.price = row.model.price;
            // Update discount
            vm.discount = row.model.discount;
        },
        fillDiscount: function() {
          var vm = this;
          vm.value.discount = vm.value.qty * vm.discount;
        }
    }
}
</script>

<template>
    <sidebar title="Tambah/Edit Item">
        <form action="#" role="form" v-on:submit.prevent="submit()">
          <div class="form-group">
            <label>Name</label>
            <input type="text" class="form-control" id="qty" placeholder="Masukkan Nama" v-model="value.name"/>
          </div>
          <div class="form-group">
            <label>Harga</label>
            <input type="number" class="form-control" id="price" placeholder="Masukkan Harga" v-model="value.price"/>
          </div>
          <div class="form-group">
            <label>Keterangan</label>
            <textarea rows="4" class="form-control" v-model="value.notes"></textarea>
          </div>
          <div class="form-group">
            <label>Position</label>
            <input type="number" class="form-control" id="position" placeholder="Masukkan Position" v-model="value.position"/>
          </div>
          <div class="text-right">
            <button type="button" class="btn btn-primary" v-on:click="$sidebar.hide()">Close</button>
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
    </sidebar>
</template>
