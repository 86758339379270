<script>
import SidebarDetail from './Sidebar/SidebarInvoiceDetail.vue';

export default {
    data: function() {
        return {
            sidebarDetail: SidebarDetail,
            detail: {},
            payment: {},
            data: {},
            options: {
                status: undefined,
                person: undefined
            },
            log: []
        };
    },
    created: function() {
        this.$sidebar.setComponent(SidebarDetail);
    },
    mounted: function() {
      var vm = this;

      vm.load();
    },
    watch: {
        $route (to, from){
          var vm = this;

          vm.load();
        }
    },
    computed: {
        sortedDetail: function() {
            var vm = this;

            return _.sortBy(vm.data.detail, 'position');
        },
        /*
        totalPayment: function() {
            var vm = this;

            var totalPayment = 0;
            _.each(vm.data.payment, function(num) {
                var amount = parseFloat(num.amount);
                if (num.payment_category_name == 'kelebihan-bayar' && amount < 0) {
                    totalPayment += Math.abs(amount);
                } else if (num.payment_category_name == 'kelebihan-bayar' && amount > 0) {
                    totalPayment += 0;
                } else if (num.payment_category_name == 'selisih-pembayaran' && amount < 0) {
                    totalPayment += Math.abs(amount);
                } else if (num.payment_category_name == 'perusahaan') {
                    totalPayment += Math.abs(amount);
                } else {
                    totalPayment += amount;
                }
                // console.log(num);
            });

            return totalPayment;
        }
        */
    },
    methods: {
        load: async function() {
            //this method for formatting data based on route to avoid undefined
            var vm = this;

            if (vm.$route.params.id) {
              await vm.create();
            } else {
                vm.createEmptyData();
                // vm.billDateFrom();
                // vm.billDateTo();
            }

            vm.newDetail();
            vm.loadCategory();
            vm.loadPerson();
            // vm.newPayment();
            // await vm.loadPerson();
            // vm.loadLogMail();
        },
        createEmptyData: function() {
            var vm = this;

            // var now = new Date();

            // if (now.getDate() > 15 && now.getMonth() == 11){
            //     var date = new Date(now.getFullYear() + 1, 0, 15);
            // } else if (now.getDate() > 15 && now.getMonth() != 11) {
            //     var date = new Date(now.getFullYear(), now.getMonth() + 1, 15);
            // } else {
            //     var date = new Date(now.getFullYear(), now.getMonth(), 15);
            // }

            // var x = new moment(now);
            // var y = new moment(date);
            // var duration = y.diff(x, 'days');

            var data = {
                id: null,
                category_id: null,
                person_id: null,
                ref_no: null,
                company_name: null,
                company_person_name: null,
                address: null,
                phone: null,
                email: null,
                notes: null,

                created: vm.$df.iso(new Date()),
                paid: null,
                total: 0, // Total Detail
                
                detail: [],
                _delete_detail: [],
            };

            vm.data = Object.assign({}, data);

            console.log('vm.data', vm.data);
        },
        create: async function() {
            var vm = this;
            vm.createEmptyData();
            // vm.loadLogMail();

            vm.$loading.on('loading');
            var resp = await vm.$api.Invoice.get(vm.$route.params.id);

            if (!resp.data.is_error) {
                var data = resp.data.data;

                // vm.payment.person_id = data.person_id;

                for (var i = 0; i < data.detail.length; i++) {
                    data.detail[i]._hash = Math.getRandomHash();
                }
                data._delete_detail = [];

                /*
                for (var i = 0; i < data.payment.length; i++) {
                    data.payment[i].person_id = data.person_id;
                    data.payment[i]._hash = Math.getRandomHash();
                }
                data._delete_payment = [];
                */

                console.log('test tagihan', data);

                vm.data = Object.assign({}, data);
            } else {
                vm.$flash.error(resp.data.message);
            }

            vm.$loading.off('loading');
        },
        newDetail: function() {
            var vm = this;

            var detail = {
                id: null,
                name: null,
                qty: 1,
                price: 1000,
                notes: null,
                position: 0,

                _hash: Math.getRandomHash()
            };

            vm.detail = Object.assign({}, detail);
        },
        addDetail: function(detail) {
            var vm = this;
            console.log('detail in adddetail', detail);

            var isNew = true;

            // Check if this job update

            var found = _.find(vm.data.detail, function(x) {
                return (x._hash === detail._hash);
            });

            if(!found) {
                vm.data.detail.push(detail);
            }

            vm.newDetail();
        },
        editDetail: function(detail) {
            var vm = this;

            vm.detail = Object.assign({}, detail);
            vm.$sidebar.show();
        },
        deleteDetail: function(detail) {
            var vm = this;

            if(detail.id)
                vm.data._delete_detail.push(detail.id);

            for (var i = 0; i < vm.data.detail.length; i++) {
                if (vm.data.detail[i]._hash === detail._hash) {
                    vm.data.detail.splice(i, 1);
                }
            }

            // vm.countTotal();
        },
        submit: async function() {
            var vm = this;

            vm.$loading.on('loading');

            var data = vm._createPostData(vm.data);
            var resp = await vm.$api.Invoice.create(data);

            if(!resp.data.is_error) {
                if (vm.$route.params.id) {
                    vm.create();
                } else {
                    vm.$router.push({ name: 'invoice-detail', params: { id: resp.data.data } });
                }

                vm.$flash.success(resp.data.message);
            } else {
                vm.$flash.error(resp.data.message);
            }

            vm.$loading.off('loading');
        },
        _createPostData: function(data) {
            var vm = this;
            var data = {
                data: JSON.stringify(data)
            };

            return data;
        },
        loadCategory: function() {
            var vm = this;

            vm.$api.Category.all({page: 'all', group_by: 'invoice'}).then(function(resp) {
                var customer = resp.data.data;

                var list = _.map(customer, function(row) {
                    return { id: row.id, text: row.label };
                });

                vm.options.status = list;
            });
        },
        loadPerson: async function() {
            var vm = this;

            vm.$loading.on('loading-customer');
            var resp = await vm.$api.Person.all({ page: 'all', order_by:{ column:'name',ordered:'asc'}});
            var data = resp.data.data;

            vm.options.person = _.map(data, function(row) {
                return {
                    id: row.id,
                    text:row.text
                }
            });

            console.log('Person', vm.options.person);
            vm.$loading.off('loading-customer');
        },
        /*
        loadLogMail: async function() {
            var vm = this;

            var resp = await vm.$api.Log.all({page: 'all', fk_id: vm.$route.params.id, category: 'email', group_by: 'email'});

            if (!resp.data.is_error) {
                vm.logMail = resp.data.data;
            } else {
                vm.$flash.error(resp.data.message);
            }
        },
        newPayment: function() {
            var vm = this;

            var payment = {
                id: null,
                category: null,
                payment_category_id: null,
                payment_account_id: null,
                payment_category_name: null,
                person_id: vm.data.person_id,
                saldo: 0,
                amount: 1000,
                amount_abs: 0,
                created: vm.$df.iso(new Date()),
                notes: null,

                _hash: Math.getRandomHash()
            };

            vm.payment = Object.assign({}, payment);
        },
        addPayment: function(payment) {
            var vm = this;

            var isNew = true;

            // Check if this job update
            for(var i=0; i<vm.data.payment.length; i++) {
                if(vm.data.payment[i]._hash === payment._hash) {
                    isNew = false;
                    payment.id = vm.data.payment[i].id;
                    vm.data.payment[i] = payment;
                }
            }

            if(isNew)
                // If new just push to detail
                vm.data.payment.push(payment);

            vm.newPayment();
        },
        editPayment: function(payment) {
            var vm = this;

            vm.payment = Object.assign({}, payment);
            vm.$sidebar.show();
        },
        deletePayment: function(payment) {
            var vm = this;

            if(payment.id)
                vm.data._delete_payment.push(payment.id);

            for (var i = 0; i < vm.data.payment.length; i++) {
              if (vm.data.payment[i]._hash === payment._hash) {
                vm.data.payment.splice(i, 1);
              }
            }
        },
        addPaket: async function(detail) {
            var vm = this;

            vm.$loading.on('loading');

            // get itemid of paket
            var resp = await vm.$api.Item.get(detail.item_id);

            var data = resp.data.data;

            var paket = data.detail;

            // Remove each detail
            _.each(vm.data.detail, function(x) {
                if(x.id)
                    vm.data._delete_detail.push(x.id);
            });
            // Clear detail
            vm.data.detail = [];

            // Add with new detail from selected package
            for (var i = 0; i < paket.length; i++) {
                console.log(i);
                console.log('datadetail in for',paket[i]);

                // get item detail where itemid
                var detail = {
                    id: paket[i].id,
                    item_id: paket[i].item_id_child,
                    item: paket[i].name,
                    vat: paket[i].vat,
                    qty: paket[i].qty,
                    discount: paket[i].discount,
                    price: paket[i].price,
                    notes: paket[i].paket_name,
                    position: paket[i].position,

                    _hash: Math.getRandomHash()
                };

                vm.data.detail.push(detail);

                vm.newDetail();
            }

            vm.$loading.off('loading');
        },
        */
        download: async function() {
            var vm = this;

            vm.$loading.on('loading');

            var resp = await vm.$api.Invoice.download(vm.data.id);

            if(!resp.data.is_error) {
                window.open(resp.data.data.url, '_blank');
            } else {
                vm.$flash.error(resp.data.message);
            }

            vm.$loading.off('loading');
        },
    }
}
</script>
<template>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline card-tabs">
              <box-overlay v-if="$loading.get('loading')" />
              <div class="card-header p-0 pt-1 border-bottom-0">
                <ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="custom-tabs-two-home-tab" data-toggle="pill" href="#custom-tabs-two-home" role="tab" aria-controls="custom-tabs-two-home" aria-selected="true">Detail</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="custom-tabs-two-tabContent">
                  <div class="tab-pane fade show active" id="custom-tabs-two-home" role="tabpanel" aria-labelledby="custom-tabs-two-home-tab">
                     <form action="#" role="form" v-on:submit.prevent="submit()">
                        <div class="row">
                          <div class="form-group col-sm-4">
                            <label>No. Invoice - AUTO</label>
                            <input type="text" class="form-control" id="ref_no" placeholder="Masukkan No. Invoice" v-model="data.ref_no"/>
                          </div>
                          <div class="form-group col-sm-4">
                            <label>Tanggal Buat</label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                              </div>
                              <datepicker v-model="data.created" v-bind:name="'tgl-buat'"/>
                            </div>
                            <!-- <small v-if="data.is_period == 1">Tanggal Buat harus diantara tanggal 1 sampai 15.</small> -->
                          </div>
                          <div class="form-group col-sm-4">
                            <label>Tanggal Bayar</label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                              </div>
                              <datepicker v-model="data.paid" v-bind:name="'tgl-buat'" />
                            </div>
                            <!-- <small v-if="data.is_period == 1">Tanggal Buat harus diantara tanggal 1 sampai 15.</small> -->
                          </div>

                        <wv-select2
                            label="Customer"
                            placeholder="Pilih Customer"
                            v-bind:col="12"
                            :options="options.person"
                            v-bind:required="true"
                            :disabled="$loading.get('loading-customer')"
                            v-model="data.person_id"></wv-select2>

                        <wv-select
                            v-bind:col="12"
                            label="Status"
                            v-model="data.status_id"
                            placeholder="Pilih Status"
                            v-bind:options="options.status"></wv-select>
                          <div class="form-group col-sm-12">
                            <label>Catatan</label>
                            <textarea class="form-control" id="notes" v-model="data.notes" rows="5"/>
                          </div>
                          <div class="col-sm-12">
                            <sidebar-button class="btn-primary"
                                            v-bind:sidebar="sidebarDetail"
                                            v-model="detail"
                                            v-bind:success="addDetail">Tambah Detail</sidebar-button>
                          </div>
                          <div class="col-sm-12 mt-3">
                              <div class="table-responsive">
                                    <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width: 10px">#</th>
                                            <th>Keterangan</th>
                                            <th class="text-right">Harga</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(tr, $index) in sortedDetail">
                                        <td>{{ tr.position }}</td>
                                        <td>
                                            {{ tr.name }} <br>
                                            <span class="small text-gray">{{ tr.notes }}</span>
                                        </td>
                                        <td class="text-right">{{ tr.price | currency }}</td>
                                        <td>
                                            <sidebar-button class="btn-warning btn-xs"
                                                    v-bind:edit="true"
                                                    v-bind:sidebar="sidebarDetail"
                                                    v-model="sortedDetail[$index]"
                                                    v-bind:success="addDetail"><i class="fas fa-pencil-alt"></i></sidebar-button>
                                            <button type="button" class="btn btn-danger btn-xs" @click="deleteDetail(tr)"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td class="text-right" colspan="2"><b>Total</b></td>
                                            <td class="text-right"><b>{{ data.total | currency }}</b></td>
                                        </tr>
                                    </tfoot>
                                    </table>
                                </div>
                          </div>
                          <div class="col-sm-12">
                                <div class="text-right">
                                    <span>Dibuat pada tanggal {{ $df.fullDateTime(data.created_at) }}.</span><br>
                                    <span>Dimodifikasi pada tanggal {{ $df.fullDateTime(data.updated_at) }}.</span>
                                </div>
                            </div>
                            <div class="col-sm-12 mt-3">
                                <div class="float-left">
                                    <button type="button" class="btn btn-primary" @click="download()"><i class="fas fa-print"></i> Print(PDF)</button>
                                </div>
                                <div class="float-right" v-if="$ac.hasAccess('invoice_create')">
                                    <button type="button" class="btn btn-default" @click="load()">Batal</button> 
                                    <button type="submit" class="btn btn-primary">Simpan</button>
                                </div>
                            </div>
                        </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
