<script>
export default {
    data: function() {
        return {
            routeName: 'dashboard-admin',
            total_bonus: {},
            statistic: {},
            search: {},
            income_idr: {}
        }
    },
    mounted: function() {
        var vm = this;

        vm.search = Object.assign({}, this.$route.query);

        vm.loadTotal();
        vm.loadStatistic();
        vm.loadTotalIncomeIdr();
    },
    methods: {
        loadTotal: async function() {
            var vm = this;

            vm.$loading.on('loading-total-bonus');

                var resp = await vm.$api.Report.dashboardAdmin.total(vm.search);
                var data = resp.data.data;

                vm.total_bonus = Object.assign({}, data);

            vm.$loading.off('loading-total-bonus');
        },
        loadStatistic: async function() {
            var vm = this;

            vm.$loading.on('loading-statistic');

                var resp = await vm.$api.Report.dashboardAdmin.statistic(vm.search);
                var data = resp.data.data;

                vm.statistic = Object.assign({}, data);

            vm.$loading.off('loading-statistic');
        },
        doSearch: function() {
            var vm = this;

            this.$router.push({name: vm.routeName, query: vm.search});

            vm.loadTotal();
            vm.loadStatistic();
            vm.loadTotalIncomeIdr();
        },
        clear: function() {
          var vm = this;
          vm.search = {};

          vm.doSearch();
        },
        loadTotalIncomeIdr: async function() {
            var vm = this;

            vm.$loading.on('loading-income-idr');

                var total = await vm.$api.Report.incomeIdr.total(vm.search);
                vm.income_idr = Object.assign({}, total.data.data);

            vm.$loading.off('loading-income-idr');
        },
    }
}
</script>

<template>
  <section class="content">
      <div class="container-fluid">
        <wv-row>
            <wv-col>
                <wv-card title="Pencarian">
                    <form action="#" role="form" v-on:submit.prevent="doSearch()">
                        <wv-row>
                            <div class="form-group col-sm-6">
                                <label>Tanggal (Dari)</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                                    </div>
                                    <datepicker v-model="search.date_from" />
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <label>Tanggal (Ke)</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                                    </div>
                                    <datepicker v-model="search.date_to" />
                                </div>
                            </div>
                            <wv-col class="text-right">
                                <wv-cancel-button v-on:click="clear()"></wv-cancel-button>
                                <wv-submit-button>Cari</wv-submit-button>
                            </wv-col>
                        </wv-row>
                    </form>
                </wv-card>
            </wv-col>
            <wv-col class="col-md-12">
                <wv-card v-bind:loading="$loading.get('loading-income-idr')" title="Omzet Perusahaan">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Keterangan</th>
                                    <th class="text-right">Nominal</th>
                                    <th>Unit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, $index) in income_idr">
                                    <td><a :href="tr.link">{{ tr.title }}</a></td>
                                    <td class="text-right">{{ tr.total | currency }}</td>
                                    <td>{{ tr.unit }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </wv-card>
            </wv-col>
            <wv-col class="col-md-6">
                <wv-card v-bind:loading="$loading.get('loading-total-bonus')" title="Total Bonus">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th>Keterangan</th>
                                <th class="text-right">Nominal</th>
                                <th>Unit</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, $index) in total_bonus">
                                    <td><a :href="tr.link">{{ tr.title }}</a></td>
                                    <td v-if="tr.unit_name == 'idr'" class="text-right">{{ tr.total | currency }}</td>
                                    <td v-else class="text-right">{{ tr.total }}</td>
                                    <td>{{ tr.unit }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </wv-card>
            </wv-col>
            <wv-col class="col-md-6">
                <wv-card v-bind:loading="$loading.get('loading-statistic')" title="Statistic">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th>Keterangan</th>
                                <th class="text-right">Nominal</th>
                                <th>Unit</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, $index) in statistic">
                                    <td><a :href="tr.link">{{ tr.title }}</a></td>
                                    <td class="text-right">{{ tr.total }}</td>
                                    <td>{{ tr.unit }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </wv-card>
            </wv-col>
        </wv-row>
      </div>
  </section>
</template>