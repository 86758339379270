// parent_id = 0 for header menu
// parent_id = digit number for submenu
// parent_id = '' single menu
export default [
    // {
    //     id: 1,
    //     parent_id: 0,
    //     header: 'DASHBOARD',
    // },
    {
        id: 21,
        parent_id: 0,
        header: 'TRANSAKSI',
    },
    {
        id: 70,
        parent_id: 21,
        label: 'Invoice',
        name: 'invoice',
        icon: 'fa-columns',
        permission: ['invoice_read']
    },
    // {
    //     id: 41,
    //     parent_id: 0,
    //     header: 'LAPORAN',
    // },
    {
        id: 61,
        parent_id: 0,
        header: 'MASTER DATA',
    },
    {
        id: 63,
        parent_id: 61,
        label: 'Admin',
        name: 'admin',
        icon: 'fa-users',
        permission: ['admin_read']
    },
    {
        id: 67,
        parent_id: 61,
        label: 'Customer',
        name: 'customer',
        icon: 'fa-address-book',
        permission: ['customer_read']
    },
    {
        id: 69,
        parent_id: 61,
        label: 'Barang Customer',
        name: 'item-customer',
        icon: 'fa-cubes',
        permission: ['item_customer_read']
    },
    {
        id: 68,
        parent_id: 61,
        label: 'Barang',
        name: 'item',
        icon: 'fa-cubes',
        permission: ['item_read']
    },
    {
        id: 65,
        parent_id: 61,
        label: 'List Kategori',
        name: 'list-category',
        icon: 'fa-list',
        permission: ['list_category_read']
    },
    /*
    {
        id: 66,
        parent_id: 61,
        label: 'Hak Akses',
        name: 'hak-akses',
        icon: '',
        permission: ['access_read']
    },
     */
    {
        id: 81,
        parent_id: 0,
        header: 'OTHER',
    },
    {
        id: 82,
        parent_id: 81,
        label: 'Configuration',
        name: 'config',
        icon: 'fa-cogs',
        permission: ['invoice_read']
    },
    {
        id: 83,
        parent_id: 81,
        label: 'Log',
        name: 'log',
        icon: 'fa-history',
        permission: ['log_read']
    },
    {
        id: 1000,
        parent_id: '',
        label: 'Logout',
        name: 'logout',
        icon: 'fa-sign-out-alt'
    }
];