<script>
import Invoice from './InvoiceIndex.vue'

export default {
    components: {
        'homepage': Invoice
    },
    data: function() {
        return {
            pageTitle: 'Invoice'
        }
    },
    created: function() {
        this.$routerMetaHandler.updateTitle(this.pageTitle);
    }
}
</script>

<template>
    <homepage />
</template>