export default function Report(vm) {
    // For Dashboard
    this.Dashboard = {
        reportGasBalance: function(params) {
            var url = '/api/report/dashboard/gas-balance?' + $.param(params);

            return vm.get(url);
        },
        reportChartProfitSharingGasFee: function(params) {
            var url = '/api/report/dashboard/profit-sharing-gas-fee?' + $.param(params);

            return vm.get(url);
        },
        reportChartProfitSharingAffiliate: function(params) {
            var url = '/api/report/dashboard/profit-sharing-affiliate?' + $.param(params);

            return vm.get(url);
        },
        reportChartProfitSharingReward: function(params) {
            var url = '/api/report/dashboard/profit-sharing-reward?' + $.param(params);

            return vm.get(url);
        },
    };
    // /For Dashboard

    // Cuti
    this.Cuti = {
        all: function(params) {
            var url = '/api/report/cuti/list?' + $.param(params);

            return vm.get(url);
        },

        category: function(id) {
            var params = {};

            var url = '/api/report/cuti/category';

            return vm.get(url);
        }
    };
    // /Cuti

    // Mutasi Stok
    this.StockFlow = {
        all: function(params) {
            var url = '/api/report/stock-flow/list?' + $.param(params);

            return vm.get(url);
        },

        category: function(id) {
            var params = {};

            var url = '/api/report/stock-flow/category';

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/stock-flow/export?' + $.param(params);

            return vm.get(url);
        }
    };
    // /Mutasi Stok

    // Stok By Item
    this.StokByItem = {
        all: function(params) {
            var url = '/api/report/stock/item-only?' + $.param(params);

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/stock/item/export?' + $.param(params);

        return vm.get(url);
        }
    };
    // /Stok By Item

    // Stok by Storage
    this.StokByStorage = {
        all: function(params) {
            var url = '/api/report/stock/list?' + $.param(params);

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/stock/storage/export?' + $.param(params);

            return vm.get(url);
        }
    };
    // /Stok By Storage

    // Trace SO/DO/SJ
    this.TraceSoDoSj = {
        all: function(params) {
            var url = '/api/report/trace-so-do-sj/list?' + $.param(params);

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/trace-so-do-sj/export?' + $.param(params);

            return vm.get(url);
        }
    };
    // /Trace SO/DO/SJ

    // Trace PR/PO/GR/GR Stored
    this.TracePrPoGrGrStored = {
        all: function(params) {
            var url = '/api/report/trace-pr-po-gr-gr-stored/list?' + $.param(params);

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/trace-pr-po-gr-gr-stored/export?' + $.param(params);

            return vm.get(url);
        }
    };
    // /Trace PR/PO/GR/GR Stored

    // Tagihan SO
    this.TagihanSo = {
        all: function(params) {
            var url = '/api/report/tagihan-so/list?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Tagihan SO

    // Tagihan PO
    this.TagihanPo = {
        all: function(params) {
            var url = '/api/report/tagihan-po/list?' + $.param(params);

            return vm.get(url);
        }
    };
    // /Tagihan PO

    // Tagihan PO - Pembayaran
    this.TagihanPoPembayaran = {
        all: function(params) {
            var url = '/api/report/tagihan-po-pembayaran/list?' + $.param(params);

            return vm.get(url);
        }
    };
    // /Tagihan PO - Pembayaran

    // Outstanding PO
    this.OutstandingPo = {
        all: function(params) {
            var url = '/api/report/outstanding-po/list?' + $.param(params);

            return vm.get(url);
        }
    };
    // /Outstanding PO

    // Tagihan Customer
    this.TagihanCustomer = {
        all: function(params) {
            var url = '/api/report/tagihan-customer/list?' + $.param(params);

            return vm.get(url);
        },
        export: function(params) {
            var url = '/api/report/tagihan-customer/export?' + $.param(params);

            return vm.get(url);
        }
    };

    // Tagihan Customer (Per Jatuh Tempo)
    this.TagihanCustomerJatuhTempo = {
        all: function(params) {
            var url = '/api/report/tagihan-customer-jatuh-tempo/list?' + $.param(params);

            return vm.get(url);
        },
        export: function(params) {
            var url = '/api/report/tagihan-customer-jatuh-tempo/export?' + $.param(params);

            return vm.get(url);
        }
    };
    // /Tagihan Customer

    // Outstanding PR
    this.OutstandingPr = {
        all: function(params) {
            var url = '/api/report/outstanding-pr/list?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Outstanding PR

    // Tagihan SI - Pembayaran
    this.TagihanSiPembayaran = {
        all: function(params) {
            var url = '/api/report/tagihan-si-pembayaran/list?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Tagihan SI - Pembayaran

    // Tagihan SO - SI
    this.TagihanSoSi = {
        all: function(params) {
            var url = '/api/report/tagihan-so-si/list?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Tagihan SO - SI

    // Mutasi WIP
    this.StockFlowWip = {
        all: function(params) {
            var url = '/api/report/stock-flow-wip/list?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Mutasi WIP

    // Stok WIP
    this.StockWip = {
        all: function(params) {
            var url = '/api/report/stock-wip/list?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Stok WIP

    // History Item Supplier/Customer
    this.LogPrice = {
        all: function(params) {
            var url = '/api/report/log-price/list?' + $.param(params);

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/log-price/export?' + $.param(params);

            return vm.get(url);
        }
    }
    // /History Item Supplier/Customer


    // Production Run Accuracy
    this.ProductionRunAccuracy = {
        all: function(params) {
            var url = '/api/report/production-run-accuracy/list?' + $.param(params);

            return vm.get(url);
        },
    }
    // /Production Run Accuracy

    // Inventory To Sales
    this.InventoryToSales = {
        all: function(params) {
            var url = '/api/report/inventory-to-sales/list?' + $.param(params);

            return vm.get(url);
        }
    }
    // Inventory To Sales

    // Production Run
    this.ProductionRun = {
        all: function(params) {
            var url = '/api/report/production-run/list?' + $.param(params);

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/production-run/export?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Production Run

    // Work Requirement
    this.WorkRequirement = {
        all: function(params) {
            var url = '/api/report/work-requirement/list?' + $.param(params);

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/work-requirement/export?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Work Requirement

    // Production Plan
    this.ProductionPlan = {
        all: function(params) {
            var url = '/api/report/production-plan/list?' + $.param(params);

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/production-plan/export?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Production Plan

    // Item Not Good
    this.ItemNotGood = {
        all: function(params) {
            var url = '/api/report/item-not-good/list?' + $.param(params);

            return vm.get(url);
        },

        category: function(params) {
            var url = '/api/report/item-not-good/category';

            return vm.get(url);
        },

        export: function(params) {
            var url = '/api/report/item-not-good/export?' + $.param(params);

            return vm.get(url);
        }
    }
    // /Item Not Good

    this.Customer = {
        all: function(params) {
            var url = '/api/report/customer/list?' + $.param(params);

            return vm.get(url);
        },

        total: function(params) {
            var url = '/api/report/customer-total?' + $.param(params);

            return vm.get(url);
        }
    }

    this.totalBonus = {
        all: function() {
            var url = '/api/report/total-bonus';

            return vm.get(url);
        },
    }

    this.Referral = {
        all: function(params) {
            var url = '/api/report/referral?' + $.param(params);

            return vm.get(url);
        },
    }

    this.totalCurrency = {
        all: function(params) {
            var url = '/api/report/total-currency?' + $.param(params);

            return vm.get(url);
        },
    }

    this.Balance = {
        all: function(params) {
            var url = '/api/report/balance?' + $.param(params);

            return vm.get(url);
        },
    }

    this.gasFee = {
        all: function(params) {
            var url = '/api/report/gas-fee?' + $.param(params);

            return vm.get(url);
        },
    }

    this.bonusMemberDashboard = {
        all: function() {
            var url = '/api/report/bonus-member-dashboard';

            return vm.get(url);
        },
    }

    this.statisticMemberDashboard = {
        all: function() {
            var url = '/api/report/statistic-member-dashboard';
            return vm.get(url);
        },
    }

    this.dashboardAdmin = {
        total: function(params) {
            var url = '/api/report/dashboard-admin-total?' + $.param(params);

            return vm.get(url);
        },
        statistic: function(params) {
            var url = '/api/report/dashboard-admin-statistic?' + $.param(params);

            return vm.get(url);
        },
    }

    this.historyGasBalance = {
        all: function(params) {
            var url = '/api/report/history-gas-balance?' + $.param(params);

            return vm.get(url);
        },
    }

    this.totalWithdraw = {
        all: function(params) {
            var url = '/api/report/total-withdraw?' + $.param(params);

            return vm.get(url);
        },
    },

    this.IncomeRewardPoint = {
        all: function(params) {
            var url = '/api/report/income-reward-point?' + $.param(params);

            return vm.get(url);
        },
        export: function(params) {
            var url = '/api/report/income-reward-point/export?' + $.param(params);

            return vm.get(url);
        },
        total: function(params) {
            var url = '/api/report/income-reward-point/total?' + $.param(params);

            return vm.get(url);
        },
    }

    this.incomeBusd = {
        all: function(params) {
            var url = '/api/report/income-busd?' + $.param(params);

            return vm.get(url);
        },
        export: function(params) {
            var url = '/api/report/income-busd/export?' + $.param(params);

            return vm.get(url);
        },
        total: function(params) {
            var url = '/api/report/income-busd/total?' + $.param(params);

            return vm.get(url);
        },
    }

    this.incomeIdr = {
        all: function(params) {
            var url = '/api/report/income-idr?' + $.param(params);

            return vm.get(url);
        },
        export: function(params) {
            var url = '/api/report/income-idr/export?' + $.param(params);

            return vm.get(url);
        },
        total: function(params) {
            var url = '/api/report/income-idr/total?' + $.param(params);

            return vm.get(url);
        },
    }
};